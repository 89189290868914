<template>
    <div>
        <div class="l-padded u-bb">
            <SearchField v-model="search" />
        </div>

        <ListHeader class="u-bb">
            <div slot="title">
                {{
                    $t('listedOf', [
                        filteredAssetTypes.length,
                        assetTypes.length,
                    ])
                }}
            </div>
        </ListHeader>

        <List
            v-infinite-scroll="increaseScrollLimit"
            infinite-scroll-distance="15"
        >
            <router-link
                v-for="assetType in assetTypesPortion"
                :key="assetType.id"
                :to="{
                    name: 'assetTypeAssets',
                    params: {
                        assetType: assetType.identifier,
                    },
                }"
            >
                <ListItem>
                    <div class="l-inline l-gap-2 l-center-v">
                        <AssetAvatar
                            :tracker="{
                                asset_details: {
                                    asset_type_marker: assetType.marker.url,
                                },
                            }"
                            :size="42"
                        />

                        <h3>{{ assetType.name }}</h3>
                    </div>

                    <span>{{ assetsByType()[assetType.id] || 0 }}</span>
                </ListItem>
            </router-link>
        </List>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import infiniteScroll from 'vue-infinite-scroll'

import AssetAvatar from '../AssetAvatar'
import List from '../List'
import ListHeader from '../ListHeader'
import ListItem from '../ListItem'
import SearchField from '../SearchField'

const defaultScrollLimit = 15

export default {
    name: 'AssetTypeListView',
    components: {
        AssetAvatar,
        List,
        ListHeader,
        ListItem,
        SearchField,
    },
    directives: {
        infiniteScroll,
    },
    data() {
        return {
            scrollLimit: defaultScrollLimit,
            scrollStep: defaultScrollLimit,
            search: '',
        }
    },
    computed: {
        ...mapState('tracker', ['assetTypes']),
        ...mapGetters('tracker', ['assetsByType']),
        assetTypesPortion() {
            return this.filteredAssetTypes.length === this.assetTypes.length
                ? this.filteredAssetTypes.slice(0, this.scrollLimit)
                : this.filteredAssetTypes
        },
        filteredAssetTypes() {
            const query = this.search.toLowerCase()
            return this.search
                ? this.assetTypes.filter(assetType =>
                      assetType.name.toLowerCase().includes(query)
                  )
                : this.assetTypes
        },
    },
    methods: {
        increaseScrollLimit() {
            if (this.scrollLimit < this.assetTypes.length) {
                this.scrollLimit += this.scrollStep
            }
        },
    },
}
</script>

<i18n>
{
    "en": {
        "listedOf": "Show {0} of {1}"
    },
    "de": {
        "listedOf": "Zeige {0} von {1}"
    },
    "fr": {
        "listedOf": "Montrer {0} de {1}"
    },
    "it": {
        "listedOf": "Listo {0} di {1}"
    }
}
</i18n>
